@import '../../../styles/variables';

.AppendixTable {
  section {
    color: $documentation-light-text-color;
    font-size: $documentation-font-size;
    margin-bottom: $spacing-amount * 2;

    &:first-of-type {
      margin-top: $spacing-amount * 2;
    }

    > span {
      display: block;
      width: 100%;
      margin-bottom: $spacing-amount / 2;

      &:first-of-type {
        font-size: 18px;
        border-bottom: $border-style;
        margin-bottom: $spacing-amount;
        width: 33%;
      }

      &:last-of-type {
        color: $green-status-color;
      }
    }
  }
}
