@import '../../../styles/variables';

.Column {
  display: block;
  width: 640px;
  max-width: 640px;

  &:not(:first-child) {
    width: 550px;
    max-width: 550px;
  }

  p {
    font-size: $documentation-font-size;
  }

  ul,
  ol {
    li {
      list-style: none;

      ul,
      ol {
        li {
          padding-left: 1em;
        }
      }
    }
  }
}
