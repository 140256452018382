@import '../../styles/variables';

.alerts-wrapper {
  padding: $spacing-amount $spacing-amount 0;
  width: 100%;

  &.hide {
    display: none;
  }

  .wrapper-label {
    color: $dark-background-color;
    font-size: 0.5em;
    font-weight: $bold;
    letter-spacing: 0.08em;
    margin-left: 2px;
    text-transform: uppercase;
  }

  .wrapper-title {
    color: #777;
    font-size: $font-size-large;
    line-height: $font-size-small;
    margin-bottom: $spacing-amount;
  }

  .alert {
    border-radius: 5px;
    color: $text-color;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
