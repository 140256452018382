@import '../ScoringRequestForm.variables';

$income-input-width: 280px;

.incomeLineWrapper {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  max-width: $income-input-width !important;

  .currencySymbol {
    font-weight: $bold;
    margin-right: $spacing-amount / 4;
  }

  .incomeInput {
    flex-grow: 20;
    width: auto;
  }

  .invalid-feedback {
    display: block;
  }
}

.inputUnits {
  margin-left: $spacing-amount * 0.25;

  select {
    width: auto;
    height: $spacing-amount * 1.1;

    &.inlineSelect {
      height: 25px;
    }
  }
}
