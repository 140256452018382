@import '../../styles/variables';

.letterhead {
  @media print {
    display: flex !important;
  }

  align-items: flex-end;
  border-bottom: 2px solid $success;
  color: $text-color;
  display: none;
  font-weight: $bold;
  margin-bottom: $spacing-amount;
  padding-bottom: $spacing-amount * 0.5;

  div {
    font-size: $font-size-small;

    &:first-child {
      flex-grow: 2;
    }
  }
}
