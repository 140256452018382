@import '../../styles/variables';

.tableHeaderSortable {
  background-color: white;
  cursor: pointer !important;
  font-size: $font-size-small;
  font-weight: $bold;
  height: 50px;
  padding: $spacing-amount / 2 !important;
  position: relative;
  vertical-align: middle !important;

  .sortIcon {
    color: $text-light-color;
    margin-right: $spacing-amount / 4;
    pointer-events: none;

    &.sortActive {
      color: $primary-color;
    }
  }

  &:hover {
    .sortIcon {
      color: $text-color;
    }
  }

  &:nth-child(1),
  &:nth-child(2) {
    width: 11%;
  }

  &:nth-child(3),
  &:nth-child(4) {
    width: 16%;
  }

  &:nth-child(5) {
    width: 7%;
  }

  &:nth-child(6) {
    width: 14%;
  }

  &:nth-child(7) {
    width: 7%;
  }

  &:nth-child(8) {
    width: 2%;
  }
}

.tableHeaderFilter {
  .formGroup {
    margin-right: $spacing-amount;
  }
}

.sortActive {
  color: $primary-color;

  &:hover {
    .sortIcon {
      color: $text-color;
    }
  }
}

.textCenter {
  text-align: center;
}
