@import '../../styles/variables';

.footerPaginationWrapper {
  display: flex;
  margin-top: $spacing-amount;

  nav {
    margin: 0 auto;
  }

  button {
    padding-top: 12px;

    &[first="true"] {
      &::before {
        content: "«";
      }
    }

    &[last="true"] {
      &::before {
        content: "»";
      }
    }

    &[aria-label="Next"] {
      span {
        &:first-child {
          color: transparent;

          &::before {
            color: $primary-color;
            content: "›";
          }
        }
      }
    }

    &[aria-label="Previous"] {
      span { /* stylelint-disable-line */ // Known stylelint issue for `no-descending-specificity`
        &:first-child {
          color: transparent;

          &::before {
            color: $primary-color;
            content: "‹";
          }
        }
      }
    }
  }
}
