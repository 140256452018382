@import '../../../styles/variables';
@import '../ScoringRequestDetails.module';

.navItem {
  cursor: pointer;

  .active,
  &:hover {
    color: $primary-color !important;
  }
}

.bankingCards {
  min-width: 100%;

  @media print {
    display: block !important;
  }
}

.tabBar {
  margin-bottom: $spacing-amount !important;

  @media print {
    display: none !important;
  }
}

.quote {
  color: $text-color;
  font-size: $font-size-medium;
  font-weight: $bold;
  margin: $spacing-amount ($spacing-amount / 2) 0;
  margin-left: -($spacing-amount / 2);
  quotes: "\201C""\201D""\2018""\2019";

  &::before {
    color: $divider-color;
    content: open-quote;
    font-size: $font-size-large * 1.5;
    line-height: $spacing-amount;
    margin-right: $spacing-amount / 4;
    vertical-align: -($spacing-amount / 2);
  }
}

.alertAt {
  font-size: $font-size-xsmall;
  padding-right: $spacing-amount;
}
