@import '../../../styles/variables';

.income-input-group {
  width: 50%;
}

.toolTipIcon {
  color: $dark-background-color;
  font-size: 15px;
  margin: ($spacing-amount / 4) ($spacing-amount / 4) 0 ($spacing-amount / 4);
}

.verticalSpacer {
  display: block;
  min-height: 500px;
  width: 150px;
}
