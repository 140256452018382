@import '../../styles/variables';

.impersonationBanner {
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: $admin-color;
  height: $spacing-amount * 2;
  margin-top: -4px;
  margin-bottom: -4px;

  @media print {
    display: none;
  }
}

.impersonationContent {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 45px;
  width: 100%;
  padding: 0 $spacing-amount;
  background-color: $admin-color;
  border: 0;
  margin-bottom: 0;
}

.impersonationMessage {
  display: flex;
  align-items: center;
  width: 100%;
}

.impersonationButton {
  display: flex;
  align-items: center;
  width: 200px;
  font-size: $font-size-normal;
}

.impersonationX {
  margin: auto;
  font-size: 2.1em;
  font-weight: 100;
}

.impersonationPopper {
  div {
    background-color: black !important;
    color: white !important;
  }

  opacity: 0.85 !important;
  margin-top: - $spacing-amount / 2;
}
