.sourceRow {
  margin-bottom: 0.1rem;
}

.sourceText {
  padding-left: 0.4rem;
}

.deleteText {
  padding-left: 0.2rem;
}

.enableDisableLabel {
  line-height: 28px;
}
