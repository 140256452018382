@import '../../../../styles/variables';

.wrapper {
  text-align: center;
  width: 100%;
  padding: $spacing-amount 0;
}

.label {
  font-weight: $bold;
}

.helpText {
  display: flex;
  justify-content: center;

  .icon {
    color: $primary-color;
    cursor: pointer;
    margin-left: $spacing-amount / 4;
    margin-right: $spacing-amount / 4;

    &:hover {
      color: $secondary-color;
    }
  }
}

.url {
  text-align: center;

  a {
    padding-left: 0 !important;
  }
}

.feedbackMessage {
  color: $primary-color;
  font-size: $font-size-small;
  margin-top: $spacing-amount / 2;
}

.hidden {
  opacity: 0;
}

.fadeIn {
  transition: opacity 1s ease;
}

.fadeOut {
  opacity: 0;
  transition: opacity 1s ease;
}
