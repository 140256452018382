@import '../../../styles/variables';

.File {
  align-items: center;
  background-color: #f8f8f8;
  border: 1px solid #eee;
  border-radius: $spacing-amount / 2;
  display: flex;
  flex-direction: row;
  font-size: $documentation-font-size;
  padding-left: $spacing-amount;
  margin: $spacing-amount;

  span {
    flex-grow: 1;
  }

  button {
    border: 1px solid gray;
    color: $green-status-color;
    font-size: 14px;
    padding: 2px 10px 0 10px;
    margin: $spacing-amount / 2;
  }

  @media print {
    button {
      display: none;
    }
  }
}

.inlineContent {
  background-color: $code-background-color;
  display: block;
  font-size: $documentation-font-size;
  overflow-y: scroll;
  padding: $spacing-amount;
  margin: 0 auto $spacing-amount auto;
  max-height: 600px;
  max-width: 94%;

  code {
    color: #edf3f8 !important;
  }
}
