@import '../../styles/variables';

.requestsHistoryContainer {
  .noRecords {
    text-align: center;
  }

  .details {
    display: none;
    background-color: orange;
    padding: 10px;
    min-width: 400px;
    max-width: 400px;
    height: 100vh;
    position: fixed;
    top: 150px;
    right: 0;
    z-index: 9999;
  }

  .exportButton {
    color: #6c757d;
    background-color: transparent;
    background-image: none;
    border-color: #6c757d;

    :hover {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
    }
  }
}
