@import '../../../styles/variables';

.printTitlePage {
  display: none;

  @media print {
    &:first-of-type {
      display: block;

      h1 {
        margin-top: 30px;
      }
    }
  }
}

.titleBlock {
  position: absolute;
  top: 300px;
  left: 200px;
}

.disclaimer {
  position: absolute;
  top: 1000px;
  left: 200px;
  margin-right: 200px;
}
