@import '../../styles/variables';
@import '../../containers/ScoringRequestDetails/ScoringRequestDetails.module.scss';

.cardWrapper {
  box-shadow: $global-shadow;
  max-width: $card-max-width;
  min-height: $card-min-height;
  margin: $spacing-amount * 2 auto;
  padding-top: $spacing-amount;
  text-align: center;
}

.cardTitle {
  border-bottom: 2px solid $primary-color;
  color: $primary-color;
  margin: 0 !important;
  padding-bottom: $spacing-amount / 2 !important;
  max-height: $spacing-amount * 3;

  > svg {
    cursor: pointer;
    height: $spacing-amount;
    position: absolute;
    left: $spacing-amount;
    top: 22px;
    width: $spacing-amount;
  }
}

.rightArrow {
  > svg {
    left: unset;
    right: $spacing-amount;
  }
}

.cardBody {
  display: flex;
  flex-wrap: wrap;
}

.bankingUploadSection {
  height: 40px;
  padding: 0 $spacing-amount / 2;
  max-height: 40px;
  text-align: right;

  button {
    border: 0;
    color: $primary-color;
    height: $spacing-amount * 1.5;

    &:link,
    &:hover,
    &:focus,
    &:visited,
    &:active {
      border: 0 !important;
      box-shadow: none !important;
    }
  }
}

.cardFooter {
  font-size: $font-size-xsmall;
  max-height: $spacing-amount * 3;

  .loanReferenceText {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: -5px;
  }
}

.printable {
  @include printableCollapseWrapper();
}
