@import '../../../styles/variables';

.inputDataGroup {
  padding-top: $spacing-amount * 2;
  width: 48%;

  &:nth-child(1),
  &:nth-child(2) {
    padding-top: 0;
  }

  &:nth-child(odd) {
    margin-right: 10px;
  }

  &:nth-child(even) {
    margin-left: 10px;
  }

  h5 {
    color: $primary-color;
  }
}

.fullWidth {
  width: 100%;
}
