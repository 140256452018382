.dropdownButton {
  height: 38px !important;
  padding-top: 9px !important;
}

.loadingIcon {
  animation-name: spin;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
