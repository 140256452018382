@import '../../../../styles/variables';

.selectedCard {
  border-color: transparent;
  box-shadow: 0 0 0 3px $primary-color;
}

.unselectedCard {
  border-color: transparent;
  box-shadow: 0 0 0 3px $light-background-color-hovered;
}

.badge {
  padding: 2px 4px 0 !important;
  margin-top: 3px;
}

.searchBadge {
  padding: 2px 4px !important;
}

.searchBox {
  li svg {
    visibility: hidden;
  }

  :global(div.wrapper) {
    border: 3px solid $light-background-color-hovered;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  :focus-within {
    :global(div.wrapper) {
      border: 3px solid $primary-color;
    }
  }
}
