@import '../../styles/variables';

$table-row-min-height: 40px;

.noRecords {
  text-align: center;
}

.actionIcons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 14pt;
}

.groups {
  font-weight: bold;
  font-size: 15px;
  border: 1px solid $code-background-color;
  border-radius: 2px;
  padding-right: 5px;
  padding-left: 5px;
  height: 20px;
}

[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

[data-tooltip]::before,
[data-tooltip]::after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

[data-tooltip]::before {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}

[data-tooltip]::after {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

[data-tooltip]:hover::before,
[data-tooltip]:hover::after {
  visibility: visible;
  opacity: 1;
}

[data-tooltip]:hover {
  color: $primary-color;
}

.tableRow {
  font-size: $font-size-small;
  min-height: $table-row-min-height;

  &:hover {
    background-color: $dark-background-color !important;
    color: #fff;
  }

  td {
    border: 1px solid #ddd;
    max-width: 300px;
    height: $table-row-min-height;
    vertical-align: middle !important;
    padding: 2px ($spacing-amount / 2) 0 !important;

    &:nth-last-child(-n+2) {
      text-align: center;
    }
  }
}

.tableHeader > table > thead > tr > th:nth-last-child(-n+2) {
  width: 7% !important;
  text-align: center;
  cursor: default !important;
}

.flowbuilderRole {
  text-transform: capitalize;
}
