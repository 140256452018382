@import '../../styles/variables';

.header-bar {
  @include no-print();

  display: flex;
  flex-direction: row;
  margin-top: $spacing-amount * 0.5;
  margin-bottom: $spacing-amount * 1.5;
  width: 100%;
  min-width: 920px;
}

.user-menu-dropdown {
  .btn-secondary {
    background: none;
    border: none;
    color: $secondary-color;
    font-size: $font-size-small;
    font-weight: $bold;

    &:hover,
    &:focus,
    &:active,
    &.dropdown-toggle {
      color: $secondary-color !important;
      background: none !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  .dropdown-menu {
    button {
      font-size: $font-size-small;
      font-weight: $bold;
    }

    svg {
      margin-right: $spacing-amount * 0.5;
    }
  }
}

.header-nav {
  align-items: center;
  color: $secondary-color;
  display: flex;
  flex-grow: 2;
  height: 35px;
  justify-content: flex-end;
  margin-top: 5px;
  margin-right: $spacing-amount;
}

.profile-icon {
  margin-top: 0.23rem;

  &:hover {
    margin-top: 0.3rem;
  }
}
