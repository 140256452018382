@import '../../styles/variables';

.button {
  display: flex;
  align-items: flex-end;
  padding: 0 $spacing-amount / 2;
}

.groups {
  font-weight: bold;
  font-size: 15px;
  border: 1px solid $code-background-color;
  border-radius: 2px;
  padding-right: 5px;
  padding-left: 5px;
  height: 20px;
}
