@import "../../styles/variables";

.home-container {
  padding-bottom: 15px;

  @media print {
    display: none;
  }
}

.print-header {
  display: none;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media print {
    display: flex;
  }
}

.centered {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: $spacing-amount / 3;
  }
}

.trade-mark-slogan {
  border-top: 2px solid $green-status-color;
  font-weight: $bold;
  margin-top: $spacing-amount * 3;
  padding-top: 10px;
  width: 380px;

  .green-text {
    color: $green-status-color;
    padding-left: 4px;
  }
}

.reportTable {
  padding: 33px 0;
  text-align: center;
}

.apex {
  white-space: nowrap;

  @media screen {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0;
    justify-content: space-between;
  }
}

.chart {
  width: 600px;

  @media print {
    display: inline-block;
    padding-right: 20px;
    box-sizing: border-box;
  }
}

.row-title {
  min-width: 160px;
}

.print-only-doc-footer {
  display: none;

  @media print {
    display: flex;
    justify-content: center;
    width: 100%;
    bottom: 0;
    line-height: 0.2;
  }
}

.chartSize {
  height: auto !important;
  width: 100% !important;
  aspect-ratio: 16 / 10 !important;
}
