.nh {
  min-height: 82vh;
}

.active {
  border-right: 5px solid #19ab6d !important;
}

.invisible {
  display: none;
}
