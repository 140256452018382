@import '../../styles/variables';

.confirmButton {
  width: $spacing-amount * 4;

  > div {
    display: block;
    margin: 0 auto;
  }
}
