@import '../../../styles/variables';

.toolTipIcon {
  color: $dark-background-color;
  font-size: 15px;
  margin: ($spacing-amount / 4) ($spacing-amount / 4) 0 ($spacing-amount / 4);
}

.shortInputGroup {
  margin-left: 0 !important;
}

.wideLabel {
  width: 158px !important;
  max-width: 158px !important;
}

.tooltipBox {
  width: 500px;
}

.currencyLineWrapper {
  align-items: baseline;
  display: flex;
  flex-direction: row;

  .currencySymbol {
    padding-top: 7px;
    width: $spacing-amount / 1.5;
  }

  .valueUnit {
    font-size: 1em;
    margin-left: 10px;
  }
}

.customWidthWithUnits {
  width: 100% !important;
}

.customShortWidthWithUnits {
  width: 100px !important;
}

.booleanToggle {
  margin-left: ($spacing-amount / 2) !important;
}

.validationMessage {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
