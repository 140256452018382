@import '../../../styles/variables';

.navSubmenuDropdown {
  display: inline-block;
  margin: 0 $spacing-amount / 1.5;
  height: 31px;
  border-bottom: 3px solid white;

  > button {
    background: none !important;
    border: 0 !important;
    color: $text-color;
    font-size: 1.2em;
    font-weight: bold;
    line-height: 0;
    padding: 0 !important;
    height: 100%;

    &:active,
    &:focus,
    &:hover {
      background: none !important;
      box-shadow: none !important;
      color: $text-color !important;
    }
  }

  &:hover,
  &.selected {
    border-color: $green-status-color;
  }
}

.iconNav {
  margin: 0;

  &:hover {
    border: none;
  }
}

:export {
  /* NB 2024-04-23 APP-4401
    ignoreSelectors implementation for property-case was not introduced until v14.3.0 so we will have to
    upgrade stylelint (APP-4062) before we can properly configure it. In the meantime we can ignore the line */
  /* stylelint-disable-next-line property-case */
  iconColour: $primary-color;
}
