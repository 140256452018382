@import '../../../styles/variables';

.submenuDropdown {
  display: inline-block;
  margin: 0 $spacing-amount / 1.5;
  height: 31px;
  border-bottom: 3px solid white;

  > button {
    background: none !important;
    border: 0 !important;
    color: $text-color;

    &:active,
    &:focus,
    &:hover {
      background: none !important;
      box-shadow: none !important;
      color: $text-color !important;
    }
  }
}
