@import "~bootstrap/scss/bootstrap-grid.scss";

.configItem {
  // 1 Column, no border
  border: none;

  // 2 Columns, 1 border
  @include media-breakpoint-only(sm) {
    &:nth-child(2n) {
      border-left: 1px solid rgba(0, 0, 0, 0.125);
    }
  }

  // 3 Columns, 2 borders
  @include media-breakpoint-up(md) {
    &:nth-child(3n),
    &:nth-child(3n+2) {
      border-left: 1px solid rgba(0, 0, 0, 0.125);
    }
  }
}
