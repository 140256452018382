@import "../../styles/variables";

.environmentContainer {
  margin: auto;
  width: 80%;
  padding: 0 0 $spacing-amount*2 0;
}

.fieldHeader {
  display: flex;
  justify-content: space-between;
  font-size: medium;
  font-weight: bold;
}

.projectField {
  min-width: 15em;
  width: 30%;
  padding-top: 0.5em;
}

.nameField {
  min-width: 22em;
  width: 35%;
  padding-top: 0.5em;
}

.statusField {
  min-width: 6em;
  width: 10%;
  padding-top: 0.5em;
  text-align: center;
}

.configuredField {
  min-width: 7em;
  width: 15%;
  padding-top: 0.5em;
  text-align: center;
}

.actionsField {
  min-width: 8em;
  width: 10%;
  padding-left: 0.6em;
}

.configuredEnv {
  font-weight: bold;
  border: 1px solid $code-background-color;
  border-radius: 2px;
  padding: $spacing-amount / 5 $spacing-amount / 5 0 $spacing-amount / 5;
  margin: $spacing-amount / 10;
}
