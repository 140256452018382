@import '../../styles/variables';
@import './ScoringRequestForm.variables';

.auto-loan-container {
  align-content: space-between;
  display: flex;
  flex-wrap: wrap;
  font-size: $font-size-small;
  min-width: 1200px;

  h4 {
    color: $text-color;
    font-weight: $bold;
    margin-bottom: $spacing-amount * 1.5;
    width: 100%;
  }

  .form-group-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 100%;
    max-height: 1000px;
  }

  .person-input-group {
    width: 50%;

    > .form-group {
      width: 50%;

      > .form-group {
        margin-right: 20px;
      }

      .person-residence-input-group {
        margin-bottom: 0;
        // max-height: 500px;
        padding-bottom: 0;
      }
    }
  }

  .deal-information-input-group {
    width: 25%;
  }

  select {
    align-items: flex-end;
    color: $text-color;
    display: flex;
    font-size: 1em;
    height: $input-height;
    width: $input-width;
    padding: 0;

    &.large-width {
      width: 250px;
    }
  }

  label {
    color: $text-color;
    display: block;
    margin: 0;
    max-width: $input-width;
  }

  input {
    color: $secondary-color;
    font-size: 1em;
    height: $input-height;
    letter-spacing: 0.06em;
    margin: 1px 0 0 0;
    padding: 5px 2px 2px 5px;
    width: $input-width;

    &.short-width {
      display: inline-block;
      width: 75px;
    }

    &.medium-width {
      display: inline-block;
      width: 175px;
    }

    &.large-width {
      display: inline-block;
      width: 250px;
    }
  }

  .input-units {
    margin-left: $spacing-amount * 0.25;

    select {
      width: auto;
      height: $spacing-amount * 1.1;

      &.inlineSelect {
        height: 25px;
      }
    }
  }

  .btn.submit {
    margin-top: $spacing-amount;
    width: 150px;
  }

  .required-field {
    border: 1px solid $dark-background-color;
  }

  .form-spacer {
    display: block;
    height: $spacing-amount;
    width: 100%;
  }
}

.container {
  padding-left: 0 !important;
}
