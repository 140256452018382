@import '../../../styles/variables';
@import '../ScoringRequestDetails.module';

.noPrint {
  @include no-print();
}

.iconContainer {
  display: flex;

  .copyJsonIcon {
    margin-left: auto;
    cursor: pointer;
  }
}

.callbackHistoryMessage {
  width: 100%;
  text-align: center;
}

.callbackHistoryItem {
  margin: $spacing-amount * 2 0;
}

.eventHeader {
  display: flex;
  justify-content: space-between;
  background-color: $light-background-color;
  padding: $spacing-amount / 4;
  align-items: start;
}

.eventTimestamp {
  font-weight: bold;
}

.callbackUrl {
  font-family: Courier, serif;
}

.eventHeaderItem {
  padding: $spacing-amount / 4;
}

.eventDetails {
  padding: $spacing-amount / 4 $spacing-amount / 2;
}

.error {
  color: $error-color;
  text-align: left;
  padding: $spacing-amount / 4 0;
}
