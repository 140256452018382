@import "../../../styles/variables";

.productRow {
  display: flex;
  justify-content: center;
  width: 100%;

  span {
    display: block;
    line-height: $spacing-amount;
    padding: $spacing-amount / 8 $spacing-amount / 2;
    font-size: $font-size-small;
  }

  &:nth-child(odd) {
    background: $light-background-color;
  }
}

.productCodeInputColumn {
  width: 20%;
}

.flowSelectColumn {
  width: 20%;
  padding-left: $spacing-amount/2;
}

.descriptionInputColumn {
  width: 45%;
  padding-left: $spacing-amount/2;
}

.addNewProductConfigColumn {
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-left: $spacing-amount/2;
}

.productCodeDisplayColumn {
  display: inline-block;
  width: 15%;
  text-align: left;
}

.flowDisplayColumn {
  display: inline-block;
  width: 20%;
  text-align: left;
}

.descriptionDisplayColumn {
  display: inline-block;
  width: 40%;
}

.addButtonColumn {
  display: inline-block;
  width: 25%;
  font-weight: bold;
  text-align: center;
}

.loaderSpacing {
  min-height: 6rem;
}

.validationMessage {
  color: $error-color;
  margin: 0;
  font-size: $font-size-small;
  padding: 0 $spacing-amount / 2 $spacing-amount / 2 $spacing-amount / 2;
}
