@import '../../styles/variables';
@import '../ScoreCard/ScoreCard.module.scss';

$applicant-info-card-width: 360px;

.detailsContainer {
  width: $applicant-info-card-width;

  :global .react-json-view { // stylelint-disable-line selector-pseudo-class-no-unknown
    margin-top: 10px;
    font-family: inherit !important; // to override default component/module font
  }
}
