@import '../../../styles/variables';

$logo-size: 220px;

.borderlessButton {
  border: none;
}

.logo {
  border: $border-style;
  box-shadow: $global-shadow;
  cursor: pointer;
  transition: $animation-effect;
  max-width: $logo-size;
  max-height: $logo-size;

  &:hover {
    border: $border-hover-style;
    box-shadow: $global-hover-shadow;
  }
}
