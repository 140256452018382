@import '../../styles/variables';

.disabledButtons {
  button {
    opacity: 0.2 !important;
    pointer-events: none !important;
  }
}

.topRow {
  align-items: center;
  display: flex;
  margin-bottom: $spacing-amount * 2;
  max-width: 100%;
}

.indicatorGroup {
  @include no-print();

  display: flex;
  flex-direction: row;
  flex-grow: 1;
  font-size: $font-size-small;
}

.formStepIndicator {
  color: $dark-background-color;
  border-top: 3px solid $dark-background-color;
  display: block;
  font-weight: $bold;
  padding-top: $spacing-amount / 4;
  width: 115px;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:last-child {
    flex-grow: 1;
  }

  &.highlighted {
    color: $green-status-color;
    border-top: 3px solid $green-status-color;
  }
}

.buttonGroup {
  @include no-print();

  align-items: flex-end;
  display: flex;
  flex-direction: row-reverse;

  button {
    color: $light-background-color;
    font-size: 0.9em;
    height: $spacing-amount * 1.5;
    line-height: 32px;
    margin-left: $spacing-amount;
    padding: 0 ($spacing-amount * 0.5);
  }

  .btnPrimary {
    order: 1;
    background-color: $green-status-color;
  }

  .btnSecondary {
    background-color: white;
    border: 2px solid $dark-background-color;
    color: $text-color;
    order: 2;
  }

  .btnDanger {
    background-color: $error-color;
    order: 3;
  }

  .order-1 {
    order: 1;
  }

  .order-2 {
    order: 2;
  }

  .btnFullDetails {
    color: $secondary-color;
  }
}
