@import '../../styles/variables';

.all-notifications-container {
  height: 100%;
}

.documentation-container {
  color: $documentation-light-text-color;
  letter-spacing: 0.01em;
  max-width: 1270px;
  margin: auto;

  a {
    color: $green-status-color;
    margin: 0;
    padding: 0;
    text-decoration: underline;

    &:hover {
      color: $primary-color;
    }
  }

  h1 {
    font-size: 30px;
    font-weight: $bold;
    letter-spacing: 0.02em;
    margin-bottom: $spacing-amount;
  }

  .doc-title {
    font-weight: 500;
    font-size: 24px;
  }

  h2 {
    font-size: 21px;
    margin-top: $spacing-amount * 2;
    margin-bottom: $spacing-amount * 0.5;
  }

  h3 {
    font-size: 17px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: $spacing-amount * 1.5;
    margin-bottom: $spacing-amount * 0.5;
  }

  h4 {
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    margin-top: $spacing-amount * 1.5;
    margin-bottom: $spacing-amount * 0.5;
  }

  code {
    color: $green-status-color;
  }

  h6 {
    code {
      font-size: 1em;
    }
  }

  p {
    color: $documentation-light-text-color;
    letter-spacing: 0.02em;
    line-height: 1.6em;
    margin-bottom: 0;

    strong {
      font-weight: $bold;
    }

    &.with-top-margin-break {
      display: block;
      margin-top: $spacing-amount * 2;

      &:first-of-type {
        margin-bottom: $spacing-amount / 2;
      }
    }
  }

  sup {
    margin-right: 2px;
  }

  pre {
    padding-bottom: $spacing-amount;

    code {
      font-family: $code-font-family;
    }
  }

  ol {
    font-size: 15px;
    list-style: decimal;
    margin: ($spacing-amount / 2) 0 0 $spacing-amount;

    > li {
      margin-bottom: $spacing-amount / 2;

      span {
        display: block;
        margin: 0 0 ($spacing-amount / 2) $spacing-amount;

        &:first-of-type {
          margin-top: $spacing-amount / 2;
        }
      }

      .bullet-item {
        &::before {
          content: '• ';
        }
      }
    }
  }

  > div > section {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    padding: ($spacing-amount * 2) 0;
    justify-content: space-between;
  }

  .no-bottom-margin-heading {
    h1 {
      margin-bottom: 0;
    }
  }

  .required-block-label {
    color: $warning-color;
    display: block;
    padding-bottom: $spacing-amount * 1.5;
  }

  @media screen {
    > div > section {
      border-top: $border-style;

      &:first-of-type {
        border: none;
      }
    }
  }

  @media print {
    > div > section {
      page-break-before: always;
    }
  }
}

.print-only-doc-footer {
  display: none;
}

@media print {
  .print-only-doc-footer {
    display: block;
    width: 100%;
    height: 18px;
    position: fixed;
    bottom: 0;
    text-align: center;
  }
}
