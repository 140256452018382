@import '../../styles/variables';

$animationDuration: 2s;
$loaderSize: 75px;
$minBorderWidth: 5px;
$maxBorderWidth: $loaderSize / 2 - $minBorderWidth;

.container {
  display: flex;
  flex-direction: column;
  height: $loaderSize;
  justify-content: center;
  left: calc(50% - 36px);
  margin-top: $spacing-amount * 0.5;
  max-height: $loaderSize;
  max-width: $loaderSize;
  position: absolute;
  width: $loaderSize;
}

.loader {
  border: $maxBorderWidth $green-status-color solid;
  border-radius: 50%;
  width: $loaderSize;
  height: $loaderSize;
  margin-left: auto;
  margin-right: auto;
  animation-name: dot-loader;
  animation-duration: $animationDuration;
  animation-iteration-count: infinite;
}

@keyframes dot-loader {
  0% {
    border-width: $minBorderWidth;
    height: $loaderSize/2;
    width: $loaderSize/2;
    transition-timing-function: ease-in;
  }

  50% {
    border-width: $maxBorderWidth;
    height: $loaderSize;
    width: $loaderSize;
    transition-timing-function: ease-out;
  }

  100% {
    border-width: $minBorderWidth;
    height: $loaderSize/2;
    width: $loaderSize/2;
    transition-timing-function: ease-in;
  }
}
