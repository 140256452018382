@import '../../../../styles/variables';

$table-min-with: 1150px;

.tableWrapper {
  max-height: 500px;
  overflow-y: scroll;
  min-width: $table-min-with;
  border: $border-style;

  @media print {
    height: auto !important;
    max-height: inherit !important;
    overflow-y: auto !important;
  }
}

.header {
  font-weight: $bold;
  text-align: left;
  text-transform: capitalize;
  border-bottom: 1px solid black;

  td {
    font-size: 1em !important;
    text-align: left !important;
    border-left: none !important;
    border-right: none !important;
  }
}

.table {
  font-size: $font-size-small;
  max-height: 500px;
  min-width: $table-min-with;
  overflow-y: scroll;
  width: 100%;
  word-wrap: break-word;
  table-layout: fixed;

  tr {
    &:nth-child(even) {
      background-color: $light-background-color;
    }

    &:hover {
      background-color: $light-background-color-hovered;
    }

    height: 30px;
    line-height: 30px;
    text-align: left;

    td {
      padding: 0 ($spacing-amount / 4);
      border-left: 2px solid white;
      border-right: 2px solid white;

      &[type=date] {
        width: 70px;
      }

      &[type=description] {
        width: 360px;
      }

      &[type=debit],
      &[type=credit],
      &[type=balance] {
        width: 80px;
        text-align: right;
      }

      &[type=id] {
        font-size: $font-size-small;
        width: 150px;
      }

      &[type=category] {
        width: 200px;
      }

      &[type=flags] {
        width: auto;
      }
    }
  }

  &.headerTable {
    tr {
      &:hover {
        background: none !important;
      }
    }
  }
}
