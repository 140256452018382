@import '../../styles/variables';

.logoHolder {
  display: flex;
  justify-content: space-evenly;
  margin-top: $spacing-amount * 3;
}

.modalWrapper {
  max-width: 640px !important;
  width: 640px !important;

  .modalHeader,
  .modalFooter {
    padding: $spacing-amount !important;
  }
}

.responseMessage {
  text-align: center;
  margin: 0 40px;
  padding-top: 0 !important;

  &.error {
    color: $error-color;
  }

  &.success {
    color: $primary-color;
  }
}

.centeredText {
  text-align: center;
}
