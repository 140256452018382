@import '../../styles/variables';
@import '../../containers/ScoringRequestDetails/ScoringRequestDetails.module.scss';

$card-max-width: 800px;
$card-min-height: 300px;

.cardWrapper {
  box-shadow: $global-shadow;
  max-width: $card-max-width;
  min-height: $card-min-height;
  margin: $spacing-amount * 2 auto;
  padding-top: $spacing-amount;
  text-align: center;
}

.cardTitle {
  border-bottom: 2px solid $primary-color;
  color: $primary-color;
  margin: 0 !important;
  padding-bottom: $spacing-amount / 2 !important;
  max-height: $spacing-amount * 3;

  svg {
    cursor: pointer;
    height: $spacing-amount;
    position: absolute;
    right: $spacing-amount;
    top: $spacing-amount * 1.1;
    width: $spacing-amount;
  }
}

.cardFooter {
  font-size: $font-size-xsmall;
  max-height: $spacing-amount * 5;

  .loanReferenceText {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: -($spacing-amount / 4);
    overflow-wrap: anywhere;
    text-align: left;

    .id {
      padding-right: $spacing-amount;
    }
  }
}

.inline {
  margin: 0 !important;
}

.printable {
  @include printableCollapseWrapper();
}
