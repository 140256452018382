@import '../../../../../styles/variables';

$indent: 12px;

.textReport {
  text-align: left;
  padding: 2rem;
  margin: 0 10rem;
}

.noReport {
  font-size: large;
  margin-bottom: $spacing-amount;
}
