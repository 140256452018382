@import '../../../styles/variables';

.AttributesTable {
  font-size: 14px;
  letter-spacing: 0.04em;

  h2 {
    border-bottom: $border-style;
    color: gray;
    font-size: $documentation-font-size !important;
    text-transform: uppercase;
    margin-bottom: $spacing-amount * 2 !important;
  }

  section {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: $spacing-amount;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.indent {
  margin-bottom: 0 !important;
  padding-left: $spacing-amount * 2;
}

.extraWideLeft {
  div[class*="_tableRowLeft_"] {
    min-width: 220px;
  }
}
