@import '~bootstrap/scss/bootstrap';
@import '../../styles/variables';

.uploadModal {
  form {
    display: flex;
    align-items: center;
  }

  select {
    @extend .form-control;

    display: block;
    height: 2em;
    width: 150px;
    font-size: 0.8em;
    align-items: flex-end;
  }

  button {
    height: 2em;
  }
}

.uploadButton {
  color: $primary-color !important;
  position: absolute;
  padding: 0 !important;
  right: $spacing-amount;

  &:active,
  &:link,
  &:focus {
    box-shadow: none !important;
  }
}

.uploadDataSourceIcon {
  margin-left: $spacing-amount;
  position: initial !important;
}

.chooseFileInput {
  @extend .form-control;

  align-items: flex-end;
  margin-left: $spacing-amount*0.5;
  padding: 2px 12px;
}

@mixin alertBar {
  margin-top: $spacing-amount*0.25;
  padding: $spacing-amount*0.5 $spacing-amount*0.5;
}

.alertDanger {
  @extend .alert-danger;

  @include alertBar;
}

.alertSuccess {
  @extend .alert-success;

  @include alertBar;
}
