@import '../../../styles/variables';

$formFieldLeftPadding: $spacing-amount / 4 !important;

.header {
  margin-left: $spacing-amount / 4;
}

.addressForm {
  width: 450px !important;
  max-width: 450px !important;
  font-size: $font-size-small;

  label {
    font-weight: $bold;
    margin-bottom: 0;
    padding-left: $formFieldLeftPadding;
    padding-top: $spacing-amount / 2;
  }

  input,
  select {
    margin-bottom: $spacing-amount / 2;
    margin-left: $spacing-amount / 4;
    padding-left: $formFieldLeftPadding;
    max-width: 400px;

    &.is-invalid {
      opacity: 0.2 !important;
      background-position: calc(100% - 10px) !important;
    }
  }

  input {
    height: 32px !important;
    padding-top: $spacing-amount / 2;
  }

  select {
    align-items: flex-end;
    height: 32px !important;
  }
}

.footer {
  button {
    &:last-child {
      margin-right: $spacing-amount / 2;
    }
  }
}

.errorMessage {
  color: $error-color;
  display: block;
  flex-grow: 2;
  font-size: $font-size-small;
}

.confirmButton {
  width: $spacing-amount * 4;

  > div {
    display: block;
    margin: 0 auto;
  }
}
