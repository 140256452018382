@import '../../../styles/variables';
@import '../ScoringRequestForm.variables';

.inviteBlock {
  align-items: flex-end;
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 30px;
  width: $input-width;

  #sendTrustStartInviteToggle {
    margin-left: 0 !important;
  }
}

.inviteToggle {
  margin-left: ($spacing-amount / 2) !important;
}
