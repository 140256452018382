@import "../../../styles/variables";

.deploymentDetailsTitle {
  border-bottom: 2px solid $primary-color;
  margin: 0 !important;
  padding: 0.75em 0 !important;
  max-height: 4em;
  text-align: center;

  h3 {
    font-size: x-large;
    font-weight: bold;
    padding: 0.6em;
  }
}

.cardBodyLabel {
  font-weight: bold;
  text-align: right;
  width: 6.2em;
  padding-right: 0.4em;
  display: inline-block;
}

.loaderSpacing {
  min-height: 6rem;
}
