@import '../../../styles/variables';

.allowedDataSourceList {
  color: $documentation-light-text-color;
  font-size: $documentation-font-size;
  letter-spacing: 0.02em;
  line-height: 1.6em;
  padding-top: $spacing-amount;

  ul {
    list-style: none;
  }

  li {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }

  .dataSourceKey {
    color: $green-status-color;
    margin-right: $spacing-amount;
    font-size: 1em;
    text-align: right;
  }

  .dataSourceLabel {
    text-align: left;
  }
}
