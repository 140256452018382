@import '../../../styles/variables';

.navIconWrapper {
  &:active,
  &:focus,
  &:hover {
    border: none;
  }

  &.selected {
    border-color: $green-status-color;
  }
}

.headerNavItem {
  border: none;
  background: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.2em;
  margin: 0 $spacing-amount / 1.5;
  padding: 0;
  border-bottom: 3px solid white;

  &.disabled {
    color: $dark-background-color;
    cursor: default;
  }

  &:hover {
    border-color: $green-status-color;

    &.disabled {
      border-color: white;
    }
  }
}

.betaSuffix {
  order: 10;

  > span {
    vertical-align: super;
    font-size: 12px;
  }
}

.selected {
  border-color: $green-status-color;
}
