@import '../../../styles/variables';

$input-width: 250px;
$input-height: 30px;
$input-vertical-spacing: 3px;

.filtersWrapper {
  border: 0 !important;
  background-color: white;
  padding: $spacing-amount / 2 !important;
}

.filtersEnabled {
  color: $primary-color;
}

.filtersDisabled {
  color: $dark-background-color;
}

.popover {
  width: 70em !important;
  max-width: 75em !important;
  box-shadow: $global-shadow;
}

.popoverTitle {
  font-size: $font-size-large !important;
  font-weight: $bold;
  padding: $spacing-amount !important;

  svg {
    color: $documentation-light-text-color;
    cursor: pointer;
    display: block;
    position: absolute;
    top: $spacing-amount;
    right: $spacing-amount;
  }
}

.popoverBody {
  padding: $spacing-amount / 2 !important;
}

.filteringWrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: $font-size-medium;
  text-align: left !important;
  margin-top: $spacing-amount / 2;
  width: 100%;
  max-height: 21em;
  justify-content: space-between;

  span {
    display: inline-block;
    margin-right: $spacing-amount * 2;
    margin-bottom: $spacing-amount / 2;
    width: 160px;
  }

  input {
    width: $input-width !important;
  }

  select {
    width: $input-width*0.33 !important;
    align-items: flex-end;
    display: flex;
    font-size: 1em;
    height: $input-height;
    padding: 0;
    margin-bottom: $input-vertical-spacing;
  }

  .filterDescription {
    margin: 0 $spacing-amount;
  }
}

.searchTextInputGroup {
  background-color: inherit;

  select {
    width: $input-width !important;
  }
}

.buttonsGroupWrapper {
  display: flex;
  flex-direction: row-reverse;

  button {
    &:nth-child(2) {
      margin-right: $spacing-amount / 2;
    }

    &:last-child {
      margin-right: 41%;
      min-width: 120px;
    }
  }
}
