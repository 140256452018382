@import '../../../styles/variables';

.tableRowItem {
  display: flex;
  flex-direction: row;
  margin-bottom: $spacing-amount * 1.5;

  &:last-child {
    margin-bottom: 0;
  }
}

.rowLabel {
  display: block;
  font-size: $documentation-attribute-label-size;
  font-weight: $bold;
  width: 100%;
}

.rowSubLabel {
  display: inline-block;
  color: gray;
  font-size: $documentation-attribute-sub-label-size;
}

.requiredLabel {
  display: inline-block;
  color: $warning-color;
  font-size: $documentation-attribute-sub-label-size;
  font-weight: $bold;
  margin-left: $spacing-amount / 2;
  text-transform: uppercase;
}

.optionalLabel {
  display: inline-block;
  color: gray;
  font-size: $documentation-attribute-sub-label-size;
  margin-left: $spacing-amount / 2;
}

.exampleLabel {
  display: block;
  font-size: $documentation-attribute-sub-label-size;
  margin-top: $spacing-amount;
  width: 100%;
}

.tableRowLeft {
  margin-right: $spacing-amount;
  max-width: 170px;
  min-width: 170px;
}
