@import '../../styles/variables';

.icon {
  @include no-print();

  transition: $animation-effect;
  transform: rotate(-90deg);
  width: 1.33rem !important;
  height: 1.33rem !important;
  cursor: pointer;
}

.flippedIcon {
  transform: rotate(0deg);
}
