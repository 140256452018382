@import '../../../styles/variables';
@import '../ScoringRequestDetails.module';

$card-max-width: 100%;
$card-min-height: 60px;

.inputDataGroup {
  padding-top: $spacing-amount * 2;
  padding-left: $spacing-amount/2;
  padding-right: $spacing-amount/2;
  width: 48%;

  &:nth-child(odd) {
    margin-right: 10px;
  }

  &:nth-child(even) {
    margin-left: 10px;
  }

  h5 {
    color: $primary-color;
    padding-top: $spacing-amount/2;
  }
}

.cardBody {
  display: flex;
  flex-wrap: wrap;
  min-height: $card-min-height * 2;
}
