@import '../../../../../styles/variables';
@import '../../../ScoringRequestForm.variables.scss';

.panel {
  padding-left: $spacing-amount * 2;

  input {
    width: ( $total-field-width - ( $spacing-amount * 2) ) !important;
    display: inline;
  }
}

:global(.panel input.currency-input) {
  width: ( $total-field-width - $symbol-width - $symbol-margin - ( $spacing-amount * 2 ) ) !important;
  display: inline;
}
