@import '../../styles/variables';

.row.apiKey {
  padding: 4px;
}

.apiKey {
  button {
    margin-left: 12px;

    span {
      margin-left: 4px;
    }
  }

  .value {
    font-family: Courier, serif;
  }

  .createdDate {
    text-align: right;
  }
}

.logos {
  &.section {
    margin-top: $spacing-amount * 2;
  }
}

.edit-action {
  width: 75px;
  margin-left: 4px;
}
