@import './variables';
@import '~bootstrap/scss/bootstrap';

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  letter-spacing: 0.02em;
  overflow: visible;

  .center-wrapper {
    flex: 1 0 auto;
    padding: 0 $spacing-amount;
    width: 100%;
    margin: 0 auto;

    &::after {
      content: '\00a0';
      display: block;
      margin-top: 20px;
      height: 0;
      visibility: hidden;
    }
  }
}

// Horizontal rule with center text
.horizontalSplit {
  color: $dark-background-color;
  overflow: hidden;
  text-align: center;

  &::before,
  &::after {
    background-color: $dark-background-color;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  &::before {
    margin-left: -50%;
    right: 0.5em;
  }

  &::after {
    left: 0.5em;
    margin-right: -50%;
  }
}

// AWS Amplify authenticator form resizing
.amplifyForm {
  &[class*='Form__formSection'] {
    margin-top: 30px;
    max-width: 90vw;
    min-width: 90vw;
    padding: 0 5px;

    @media only screen and (min-width: 600px) {
      margin-top: -$spacing-amount;
      max-width: $desktop-layout-max-width;
      min-width: $desktop-layout-max-width;
      padding: 34px;
    }
  }
}

amplify-authenticator {
  --container-height: 555px;
  --amplify-primary-color: #19ab6d;
  --amplify-primary-tint: #19ab6d;
  --amplify-primary-shade: #eee;
}

// Webapp H1 Motto
.mottoBlock {
  display: block;
  text-align: center;
  width: 100%;
}

.motto {
  color: $secondary-color;
  font-size: $font-size-large;
  font-weight: $bold;
}

.btn-primary,
.btn-primary:active,
.btn-primary:visited {
  background-color: $green-status-color !important;
  border: 0;
}

.btn-primary:hover {
  &:not(:disabled) {
    background-color: $primary-color !important;
  }
}

.btn-secondary,
.btn-secondary:active,
.btn-secondary:visited {
  background-color: $secondary-color !important;
  border: 0;
}

input {
  &.is-invalid { // Overrides Bootstrap invalid field red 'X' position
    background-position: center right 0 !important;
    padding-right: 15px !important;
  }
}

select {
  &.is-invalid { // Overrides Bootstrap invalid field red 'X' position
    background-position: calc(100% - 10px) !important;
    padding-right: 15px !important;
  }
}

input[type=number] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input[type=file] {
  font-family: $font-family-safe;
  height: 30px;
  font-size: 0.8em;
  border: 0;
}

.tooltip {
  .arrow {
    &::before {
      display: none;
    }
  }

  .tooltip-inner {
    background-color: $light-background-color;
    border: 1px solid gray;
    color: $text-color;
    font-size: 12px;
    padding: $spacing-amount / 2;
    text-align: left;
  }
}

/* 2024.09.24 - prevent modals from scrolling up to the top of the page */
.modal-open {
  overflow: visible;
  position: relative;
}

.modal {
  button {
    &.wideButton {
      width: 110px;
    }
  }

  code {
    color: $primary-color;
  }
}

.btn-disabled {
  background-color: $light-background-color !important;
  color: $text-color;
  border: $border-style;
  opacity: 0.75;
}

.fatal-error {
  margin-top: 150px;
}

/* Amplify login css */
nav {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.auth-wrapper {
  display: flex;
  margin-top: 2rem;

  --amplify-colors-border-primary: $primary-color;
  --amplify-components-fieldcontrol-focus-border-color: $primary-color;
}

.amplify-button {
  color: #eee;
}

.amplify-button[data-variation='primary'] {
  background-color: $primary-color;

  &:hover {
    opacity: 0.8;
  }
}

.amplify-icon {
  color: $secondary-color;
}

.amplify-button[data-variation='link'] {
  color: $primary-color;

  &:hover {
    text-decoration: underline;
    background: rgba(0, 0, 0, 0);
  }
}

.amplify-heading {
  font-size: $font-size-medium;
  font-weight: $bold;
}

.amplify-input {
  --amplify-components-fieldcontrol-focus-border-color: #19ab6d;
  --amplify-components-fieldcontrol-focus-box-shadow: #19ab6d;
}

.signInResetPass {
  color: $text-light-color;
  font-size: $font-size-normal;
}

html {
  overflow-y: scroll;
}
