@import '../../../styles/variables';

.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;

  span {
    display: block;
    line-height: $spacing-amount;
    padding: $spacing-amount / 8 $spacing-amount / 2;
    font-size: $font-size-small;
    width: 50%;

    &:first-child {
      text-align: right;
    }

    &:last-child {
      font-weight: $bold;
      letter-spacing: 0.06em;
      text-align: left;
    }
  }

  &:nth-child(odd) {
    background: #f4f4f4;
  }

  &.fullWidth {
    span {
      width: 100%;
    }
  }
}

.capitalized {
  text-transform: capitalize;
}
