@import '../../../styles/variables';
@import '../../ScoreCard/ScoreCard.module.scss';
@import '../../ApplicantInfoCard/ApplicantInfoCard.module.scss';

$table-row-min-height: 40px;
$table-row-loading-spacer: 120px;

.tableRow {
  cursor: pointer;
  font-size: $font-size-small;
  min-height: $table-row-min-height;

  &:hover {
    background-color: $dark-background-color !important;
    color: #fff;
  }

  td {
    border: 1px solid #ddd;
    max-width: 300px;
    height: $table-row-min-height;
    vertical-align: middle !important;
    padding: 2px ($spacing-amount / 2) 0 !important;
    overflow: hidden;

    &:nth-child(5) {
      font-size: $font-size-medium;
    }
  }
}

.leftInlineCardWrapper {
  display: inline-block;
  margin: $spacing-amount ($spacing-amount / 2);
  width: $applicant-info-card-width;
}

.rightInlineCardWrapper {
  display: inline-block;
  margin: $spacing-amount ($spacing-amount / 2);
  width: $card-max-width;
}

.requestDetailTableRow {
  text-align: center;
}

.loaderWrapper {
  height: $table-row-loading-spacer;
}

.noWrap {
  white-space: nowrap;
}
