@import '../../../../styles/variables';

.accountSection {
  align-content: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: $spacing-amount;

  &:not(:first-child) {
    margin-top: $spacing-amount * 1;
  }

  > div {
    text-align: center;
    width: 48%;
    max-width: 48%;
    margin: 0 10px;
  }

  /*
  The following unused css styles allows us to render multiple groups of account info, should the
  account data structure changes to contain nested objects with multiple properties.
  */
  &.multiSection {
    > div {
      width: 48%;

      &:nth-child(1),
      &:nth-child(2) {
        padding-top: 0;
      }

      &:nth-child(odd) {
        margin-right: 10px;
      }

      &:nth-child(even) {
        margin-left: 10px;
      }
    }
  }

  h5 {
    color: $primary-color;
    font-size: $font-size-medium;
    flex-grow: 1;
    width: 100%;
  }
}

.basicAccountInfoWrapper {
  flex: 0 0 100%;
  width: 70%;
}

.otherAccountInfoWrapper {
  flex: 0 0 30%;
  width: 30%;
}

.transactionSection {
  .transactionsWrapper {
    padding-top: ($spacing-amount / 2) !important;
    min-width: 100%;
  }

  h5 {
    color: $primary-color;
    font-size: $font-size-medium;
  }
}

.accountDivider {
  background-color: $primary-color;
  border: none;
  color: $primary-color;
  height: 2px;

  &:last-of-type {
    display: none;
  }
}
