@import '../../../styles/variables';

.shortWidth {
  strong {
    width: 100px !important;
  }
}

.CodeBlock {
  background: linear-gradient(to bottom, #fbfcfc 30px, $code-background-color 1%) !important;

  code {
    color: #edf3f8 !important;
    font-size: 13px;
  }
}

.InfoBlock {
  background: #fbfcfc;
  border: $border-style;
  border-radius: $spacing-amount * 0.5;
  display: block;
  padding: $spacing-amount $spacing-amount 0 $spacing-amount;
  width: 550px;

  &:first-child {
    margin-top: $font-size-large * 2.2;
  }

  @media print {
    margin-left: $spacing-amount;

    pre {
      border: 0;
    }
  }

  &:not(:first-of-type) {
    margin-top: $spacing-amount;
  }

  a {
    text-decoration: none !important;
  }

  h1 {
    background-color: #eef3f0;
    border-radius: 10px 10px 0 0;
    border-bottom: $border-style;
    color: #60637c !important;
    display: block;
    font-size: 14px !important;
    font-weight: 400 !important;
    margin: -$spacing-amount;
    height: $spacing-amount * 2;
    padding: 10px $spacing-amount;
    text-transform: uppercase;
    line-height: 25px;
    letter-spacing: 1px !important;
  }

  > span {
    display: block;
    font-size: 14px !important;
    letter-spacing: 0.04em !important;
    padding-bottom: $spacing-amount;
  }

  p {
    display: flex;
    flex-direction: row;
    font-size: 14px !important;
    letter-spacing: 0.04em !important;
    margin-bottom: $spacing-amount !important;

    strong {
      display: block;
      text-align: right;
      padding-right: $spacing-amount;
      width: 180px;
    }

    span {
      width: 380px !important;
    }
  }
}

.AppendixBlock {
  section {
    font-size: $documentation-font-size;
    margin-bottom: $spacing-amount * 2;

    span {
      &:first-child {
        display: block;
        font-size: 18px;
        width: 50px;
      }

      &:not(:first-child) {
        display: block;
      }
    }
  }
}
