@import '../../styles/variables';

$box-width: 280px;
$box-height: 230px;

.addressesWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: $spacing-amount / 2;
  margin-left: -10px;
}
