@import '../../styles/variables';

.chatContainer {
  :global(.bg-assistant) {
    background-color: lighten(saturate($uat-color, 10%), 40%);
  }

  :global(.bg-light-gray) {
    background-color: rgb(243 244 246);
  }

  :global(.bg-user) {
    background-color: lighten(desaturate($primary-color, 30%), 40%);
  }

  :global(.flex-1) {
    flex: 1 1 0%;
  }

  :global(.outline-none) {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  :global(.rounded-xl) {
    border-radius: 0.5rem;
  }

  :global(.w-fit) {
    width: fit-content;
  }

  :global(.scrollbar::-webkit-scrollbar) {
    height: 0.5rem;
    width: 0.5rem;
  }

  :global(.scrollbar::-webkit-scrollbar-thumb) {
    border-radius: 1rem;
    border-width: 1px;
    border-color: rgb(255 255 255);
    background-color: rgb(229 231 235);
  }

  :global(.scrollbar::-webkit-scrollbar-track) {
    background-color: transparent;
    -webkit-box-shadow: none;
  }

  :global(main.chatmain) {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    max-width: 820px;
  }

  :global(.scrollbar::-webkit-scrollbar-thumb:hover) {
    background-color: rgb(107 114 128);
  }
}

.chatButton {
  height: 4rem !important;
}
