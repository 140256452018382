@import './ScoringRequestForm.variables';

$input-vertical-spacing: 15px;

.form-group {
  margin-bottom: $input-vertical-spacing;

  &.small-bottom-margin {
    margin-bottom: 8px !important;
  }

  .row {
    margin-bottom: 0;

    .col {
      padding-left: 0;
    }
  }

  label {
    padding: 0 !important;
  }

  .form-text {
    margin-top: 0.2rem !important;
  }

  .label-main {
    padding-right: 0.5rem !important;
  }

  .label-help {
    padding-left: 0 !important;
  }

  .required-field {
    border: 1px solid #aaa;
  }

  .currency-symbol {
    padding-top: 7px;
    width: $symbol-width;
    margin-right: $symbol-margin;
  }

  .currency-input {
    width: ($total-field-width - $symbol-width - $symbol-margin) !important;
    display: inline;
  }
}
