@import '../../../styles/variables';
@import '../ManageLocations.module.scss';

.editWrapper {
  display: flex;
  font-size: 0.875em;

  > span {
    margin-right: $spacing-amount / 2;
  }

  button {
    background: 0;
    border: 0;
    font-weight: $bold;
    padding: 0;
    margin-right: $spacing-amount / 2;
    text-decoration: underline;
  }
}

.addressBox {
  border: 1px solid $dark-background-color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: $font-size-small;
  height: $box-height;
  margin: $spacing-amount / 2;
  padding: $spacing-amount;
  position: relative;
  width: $box-width;

  > div {
    display: block;
  }

  svg {
    color: $dark-background-color;
    font-size: $spacing-amount * 2;
    width: $spacing-amount * 2;
    height: $spacing-amount * 2;
    margin-bottom: $spacing-amount / 2;
  }
}

.locationName {
  font-size: $font-size-medium;
  font-weight: $bold;
}

.optionalLabelAndValue {
  display: block;
  width: 100%;
}
