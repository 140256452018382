@import '../../styles/variables';

@mixin printableCollapseWrapper() {
  @media print {
    display: block !important;
  }
}

.error {
  text-align: center;
}

.actions {
  @include no-print();

  margin: $spacing-amount * 2 auto;
  text-align: center;

  button {
    margin: 0 $spacing-amount / 2;
  }
}

.noPrint {
  @include no-print();
}

.mobileInviteModalForm {
  padding-left: $spacing-amount;

  .inputRow {
    align-items: center;
  }

  .phoneValidationResultBlock {
    .label {
      display: inline-block;
    }

    .inputRow {
      align-items: center;
      margin-bottom: 0;
    }
  }

  .phoneValidationResultBlockLoading {
    display: inline-block;
    min-height: 6em;
  }

  .phoneValidationResultBlockTitle {
    font-weight: bold;
    margin-left: -1em;
    margin-bottom: 1em;
    border-bottom: 2px solid $text-light-color;
  }
}

.subHeadingDropdown {
  margin: 1em auto 1em auto;
  width: 94%;
  color: #6c757d;
}
