@import '../../styles/variables';

.top-header-notification {
  background: $green-status-color;
  color: $light-background-color;
  position: relative;
  width: 100%;
  left: 0;
  padding: 20px 0;
  transition: padding 500ms ease-out, height 500ms ease-out;

  &.closed {
    height: $spacing-amount * 0.5;
    padding: 0;

    div {
      opacity: 0;
    }
  }

  .message {
    max-width: 420px;
    margin: 0 auto;
  }
}
