@import '../../../styles/variables';
@import '../ScoringRequestDetails.module';

.noPrint {
  @include no-print();
}

.iconContainer {
  display: flex;

  .copyJsonIcon {
    margin-left: auto;
    cursor: pointer;
  }
}
