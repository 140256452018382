@import '../../../styles/variables';

$card-section-height: 175px;
$score-attributes-text-max-width: 430px;

.cardBodyWrapper {
  min-height: $card-section-height;
  padding-bottom: 0 !important;

  h3,
  h4 {
    color: $primary;
  }

  h3 {
    margin-top: $spacing-amount;
  }
}
