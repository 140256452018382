@import '../../styles/variables';

.bankingAnalysisSection {
  h5 {
    padding-top: 1.25rem;
    color: $primary-color;
    font-size: $font-size-medium;
  }

  h6 {
    margin-top: 1rem;
  }

  [class^=InputItemRow] {
    &:first-child {
      text-decoration: underline;

      span {
        font-weight: $bold;
      }
    }
  }
}
