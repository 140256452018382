@import '../../../styles/variables';

$labelWidth: 170px;

.row.title {
  margin-top: $spacing-amount * 2;
}

.settings .label {
  font-weight: $bold;
  width: 170px;

  &:nth-child(2) {
    width: 190px;
  }

  &.editing {
    display: flex;
    flex-direction: row-reverse;
    margin-top: ($spacing-amount / 4);
    padding-right: 0;
  }
}

.settings .tag {
  text-align: right;
}

.settings .headers {
  font-weight: $bold;
  color: $primary;
}

.addCallback .btn-label {
  margin-left: 4px;
}

.configEditorWrapper {
  padding: $spacing-amount 0;
}

.configSectionHeader {
  display: flex;
  margin-top: $spacing-amount * 2;

  h3 {
    flex-grow: 1;
    margin: 0;
  }

  button {
    height: 33px;
  }
}

.configRow {
  align-items: center;
  display: flex;
  padding-left: $spacing-amount;
  max-height: 25px;
  margin-top: 12px;

  code {
    color: $primary-color;
  }

  input {
    &.wide {
      width: 790px;
    }
  }

  button {
    height: 25px;
    min-width: 70px;

    &.icon {
      color: $error-color;
      padding: 0;
    }

    &.edit {
      font-size: $font-size-small;
      line-height: 15px;
      margin-left: $spacing-amount;

      &.btn-cancel {
        border: 2px solid $dark-background-color;
        margin-right: $spacing-amount;
        margin-left: $spacing-amount / 2;
      }
    }
  }

  &.buttons-disabled {
    opacity: 0.4;
  }

  &:not(:first-child) {
    padding-top: 0;
  }

  &.noCallbacks {
    padding-top: $spacing-amount / 2;
    margin-bottom: $spacing-amount;
  }

  &.tableHeaders {
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;

    &:not(:last-of-type) {
      padding-bottom: 0;
    }
  }

  &.valuesOnly {
    padding-bottom: 10px;
    margin-bottom: 0;
    height: 40px;
    max-height: 40px;

    label {
      display: block;
      height: 23px;
      width: 190px;

      &.editing {
        text-align: right;
      }
    }

    input {
      &:first-of-type {
        margin-right: $spacing-amount;
        width: 170px;
      }

      &:last-of-type {
        width: 600px;
      }
    }

    .buttons-disabled {
      opacity: 0.4;
    }
  }
}

.codeStyleInput {
  color: $primary-color;
  font-family: $code-font-family;
  font-size: 14px;
  margin: 0;
  padding: 0;

  &:disabled {
    background: none;
  }

  &:read-only {
    border: none;
    padding: 2px 0 2px 2px;

    &:focus {
      outline: none;
    }
  }
}

.text-muted {
  margin-top: $spacing-amount / 2;
}

.help-text {
  padding-left: $spacing-amount + $labelWidth;
  padding-top: 0.25rem;
  font-size: small;
}

.selectVersion {
  margin-left: 25px;

  select {
    height: 33px;
  }
}

.callbackBtns {
  display: flex;
}

.callbackVersion {
  margin-left: 28.5rem;
}

.displayHidden {
  visibility: hidden;
}
