// LAYOUT
$spacing-amount: 20px;
$header-height: 70px;
$footer-height: 70px;
$left-rail-nav-width: 240px;
$loan-list-item-height: 70px;
$desktop-layout-max-width: 640px;
$list-padding: 10px;
$list-margin: 6px;
$app-logo-width: 180px;
$content-max-width: 1230px;

// THEME COLORS
$primary-color: #19ab6d;
$secondary-color: #000233;
$light-background-color: #f4f4f4;
$dark-background-color: #aaa;
$info-background-color: #d7e5fc;
$warning-background-color: #fff5c9;
$green-status-color: $primary-color;
$text-color: #212529;
$text-light-color: #999;
$error-color: #dc3545;
$warning-color: #ff6700;
$admin-color: #ffc107;
$uat-color: #5243aa;
$light-background-color-hovered: #e8e8e8;

// STYLING EFFECTS
$global-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
$global-hover-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25), 0 2px 10px 0 rgba(0, 0, 0, 0.2);
$animation-effect: 0.3s ease-in-out;

// DOCUMENTATIONS
$documentation-light-text-color: #333940;
$documentation-attribute-label-size: 16px;
$documentation-attribute-sub-label-size: 12px;
$documentation-font-size: 15px;
$divider-color: lightgray;
$border-style: 1px solid $divider-color;
$border-hover-style: 1px solid $text-light-color;
$code-background-color: #3b4257;
$code-font-family: "Menlo", "Consolas", monospace !important;

// BOOTSTRAP COLORS
$primary: $primary-color;
$secondary: $secondary-color;
$success: $green-status-color;

// FONT SIZING
$font-size-xsmall: 0.75em;
$font-size-small: 0.85em;
$font-size-normal: 1em;
$font-size-medium: 1.25em;
$font-size-large: 1.6em;
$font-sixscore: 5em;

// FONT WEIGHTS
$bold: 600;

// CARD
$card-max-width: 100%;
$card-min-height: 60px;
$card-title-height: 45px;

// BOOTSTRAP DEFAULT STYLE OVERRIDING
.btn {
  height: 2em;

  &:focus {
    box-shadow: none !important;
  }

  &.btn-primary {
    &:focus {
      box-shadow: none !important;
    }
  }
}

.btn-success {
  background: $green-status-color;

  &:hover {
    background: $primary-color;
  }
}

.bold {
  font-weight: $bold;
}

@mixin no-print() {
  @media print {
    display: none !important;
  }
}

.hide {
  display: none !important;
}

.capitalize {
  text-transform: capitalize;
}

$font-family-safe: "Segoe UI", sans-serif !important;
$font-family-base: "Helvetica Neue", "Segoe UI", sans-serif !important;

/* stylelint-disable property-case */
:export {
  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  lightBackgroundColor: $light-background-color;
  darkBackgroundColor: $dark-background-color;
  infoBackgroundColor: $info-background-color;
  warningBackgroundColor: $warning-background-color;
  greenStatusColor: $green-status-color;
  textColor: $text-color;
  textLightColor: $text-light-color;
  errorColor: $error-color;
  warningColor: $warning-color;
  adminColor: $admin-color;
  uatColor: $uat-color;
  lightBackgroundColorHovered: $light-background-color-hovered;
}
/* stylelint-enable property-case */
