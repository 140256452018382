@import '../../../styles/variables';
@import '../../../../node_modules/react-bootstrap-toggle/dist/bootstrap2-toggle.css';

$toggle-font-size: 0.7em;
$toggle-max-height: 22px;
$toggle-handle-padding: 20px;

a {
  color: $green-status-color;
  padding: 5px $spacing-amount * 0.5;
}

.toggle {
  border-width: 1px !important;
  border-color: $dark-background-color !important;
  font-family: Arial, Helvetica, sans-serif !important;

  .btn.toggle-on {
    font-size: $toggle-font-size !important;
    padding-right: $toggle-handle-padding !important;
    max-height: $toggle-max-height !important;
  }

  .btn.toggle-off {
    font-size: $toggle-font-size !important;
    padding-left: $toggle-handle-padding !important;
    max-height: $toggle-max-height !important;
  }

  .btn-default {
    background: white !important;
    border-color: $dark-background-color !important;
    border-width: 1px !important;
  }

  .btn-custom {
    background: $light-background-color !important;
  }
}

.form-group-wrapper {
  .row {
    label.spaced {
      width: 170px;
      padding-left: $spacing-amount * 0.75;
    }
  }

  .auto-scoring-request-form-starter {
    padding: 0 !important;
    margin-left: 0 !important;

    .row {
      margin-left: 0;
    }
  }

  .toggle {
    margin-left: $spacing-amount;
    clear: right;
  }

  input[type="radio"] {
    display: block;
    margin-left: 0 !important;
    padding-right: 20px !important;
  }

  span {
    display: inline-block;
  }

  .radio-selection {
    position: relative !important;
  }
}
