@import '../../../styles/variables';

.integration {
  display: flex;
  flex-wrap: wrap;

  img {
    display: block;
    max-width: 120px;
    margin: 0 auto;
  }
}

.inputGroup {
  .textInputWithLabel {
    align-items: baseline;
    display: flex;
    margin-bottom: $spacing-amount / 4;
    margin-right: $spacing-amount * 3;
    min-width: 600px;

    label {
      font-weight: $bold;
      text-align: right;
      margin-right: $spacing-amount / 2;
      width: 180px;

      svg {
        padding-left: $spacing-amount / 2;
        width: $spacing-amount * 1.1;
      }
    }

    input {
      max-width: 500px;
    }
  }

  .helpText {
    font-size: smaller;
    margin-left: $spacing-amount;
    margin-right: $spacing-amount * 3;
    margin-bottom: $spacing-amount;
  }
}
