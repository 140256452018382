@import '../../../styles/variables';
@import '../ManageLocations.module.scss';

.newAddressBox {
  align-items: center;
  border: 2px dashed $dark-background-color;
  display: flex;
  font-size: $font-size-medium;
  font-weight: $bold;
  flex-direction: column;
  justify-content: center;
  height: $box-height;
  width: $box-width;
  margin: 10px;

  svg {
    color: $green-status-color;
    font-size: $spacing-amount * 2;
    width: $spacing-amount * 2;
    height: $spacing-amount * 2;
    margin-bottom: $spacing-amount / 2;
  }
}
