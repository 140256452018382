@import '../../../styles/variables';
@import '../ManageLocations.module.scss';

.question {
  display: block;
  font-weight: bold;
  margin-bottom: $spacing-amount;
  width: 100%;

  svg {
    color: $warning-color;
    font-size: 1em;
    margin-right: 10px;
  }
}

.addressDisplay {
  span {
    display: block;
    width: 100%;
  }

  margin-bottom: $spacing-amount;
}

.locationName {
  font-weight: $bold;
}

.errorMessage {
  color: $error-color;
  display: block;
  flex-grow: 2;
  font-size: $font-size-small;
}

.confirmButton {
  width: $spacing-amount * 5.5;

  > div {
    display: block;
    margin: 0 auto;
  }
}
