@import '../../../styles/variables';
@import '../ScoringRequestDetails.module';

.content {
  padding-bottom: 40px;
}

.productCode {
  color: $primary-color;
  padding-top: $spacing-amount/2;
}

.l {
  width: 100% !important;
}

.m {
  width: 50%;
}

.table {
  width: 94%;
  margin: auto;

  caption {
    text-align: left;
    caption-side: top;
  }

  th {
    font-weight: 600;
    letter-spacing: 0.06em;
  }
}

.tbody {
  tr:nth-child(even) {
    background: #f4f4f4;
  }
}

.modalButton {
  padding: 0;
  border: none;
  background: none;
  color: $primary-color;

  &:hover {
    color: $text-color;
  }
}

.cardBody {
  display: flex;
  flex-wrap: wrap;
  min-height: $card-min-height * 2;
  padding: 40px 50px;

  h5 {
    color: $primary-color;
    padding-top: $spacing-amount/2;
  }
}
