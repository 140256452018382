@import '../../../styles/variables';

.button {
  font-size: $font-size-normal !important;
  padding: 0 !important;
  text-align: center;
  position: relative;
  width: 100px;

  span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}
