@import '../../../styles/variables';
@import '../ScoringRequestDetails.module';

.info {
  position: absolute;
  right: 16px;
}

.helpModal {
  max-width: 750px !important;
}

.incomeSourceCollapse {
  max-width: 24px;
  min-width: 24px;
}

.selectIncomeSourceCheckbox {
  width: 20px;
  height: 25px;
  cursor: pointer;
  display: inline-block;
}

.lineCheckboxLabelWrapper {
  display: grid;
  grid-template-columns: 16px auto;
  gap: 0.5em;

  label {
    cursor: pointer;
  }
}

.selectHighConfidenceLineCheckbox::before {
  box-shadow: inset 1em 1em $primary-color !important;
}

.selectHighAndOtherConfidenceLineCheckbox::before {
  box-shadow: inset 1em 1em $uat-color !important;
}

.selectHighConfidenceLineCheckbox,
.selectHighAndOtherConfidenceLineCheckbox {
  appearance: none;
  background-color: #fff;
  cursor: pointer;
  margin: 0;
  width: 20px;
  height: 20px;
  border: 1.5px solid $text-color;
  border-radius: 2px;
  transform: translateY(-1px);
  display: grid;
  place-content: center;

  &::before {
    content: "";
    width: 14px;
    height: 14px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em $text-color;
  }

  &:checked {
    &::before {
      transform: scale(1);
    }
  }
}

.clickable {
  cursor: pointer;
}

.selectedCard {
  background-color: $light-background-color !important;
}

.stickyStatsBox {
  position: sticky;
  top: 4em;
  overflow-y: auto;
  max-height: calc(100vh - 4em);

  @media print {
    display: initial;
  }
}

.printable {
  @include printableCollapseWrapper();
}

.helpButton {
  padding-right: 6px !important;
}

.chartSize {
  height: auto !important;
  width: 100% !important;
  aspect-ratio: 16 / 10 !important;
}

.legendDisabled {
  color: $dark-background-color;
}

.cursorPointer {
  cursor: pointer;
}
