@import '../../../styles/variables';

$card-section-height: 175px;
$score-attributes-text-max-width: 430px;

.scoreTimeStamp {
  color: $primary-color;
  font-size: $font-size-xsmall !important;
  font-weight: normal !important;
  width: 100%;
}

.scoreNumberWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 40%;

  .scoreNumber {
    color: $primary-color;
    font-size: $font-sixscore;
    font-weight: $bold;
    margin-top: -$spacing-amount;
    width: 100%;
  }
}

.cardBodyWrapper {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  min-height: $card-section-height;
  padding-bottom: 0 !important;

  .detailsWrapper {
    font-size: $font-size-small;
    text-align: left;
    margin-left: $spacing-amount;
    max-width: $score-attributes-text-max-width;

    .attributesListing {
      span {
        font-weight: $bold;
      }

      li {
        list-style-type: circle;
        margin-left: $spacing-amount;
      }
    }
  }
}

.showPreviousScoresButtonWrapper {
  @include no-print();

  display: flex;
  flex-grow: 1;
  margin-top: -($spacing-amount * 2);
  width: 100%;

  button {
    background: none;
    border: 0;
    color: $primary-color;
    font-size: $font-size-small;
    font-weight: $bold;
    height: $spacing-amount;
    width: 40%;
  }
}

.previousScoreNumber {
  &.cardBodyWrapper {
    border-top: $border-style;
  }

  .scoreNumber {
    color: $dark-background-color !important;
    margin-top: 0;
  }

  .scoreTimeStamp {
    color: $text-light-color;
  }
}
