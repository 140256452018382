@import '../../../../styles/variables';

$indent: 12px;

.reportBody {
  .container {
    margin-bottom: 20px;
    text-align: left;

    h5 {
      color: $primary-color;
      font-weight: bold;
    }
  }

  .label {
    font-weight: bold;
    display: flex;
    align-items: flex-end;
  }

  .pullRight {
    justify-content: flex-end;
    text-align: right;
  }

  .shortDate {
    max-width: 120px !important;
  }
}

.reportHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: center;
  padding: $spacing-amount;

  h4 {
    color: $primary-color;
    text-align: left;
  }

  .logo {
    width: 200px;
  }

  .order {
    width: 50%;
    text-align: left;

    .orderHeader {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      text-transform: uppercase;

      button {
        color: white;
      }

      .subtext {
        padding-top: $spacing-amount/2;
        font-weight: normal;
      }
    }

    .orderDetail {
      .label {
        margin: 0 $indent;
        font-weight: bold;
      }
    }
  }
}

.pdfContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
