@import '../../../styles/variables';

.odometerUnitsWrapper {
  display: flex;
  flex-direction: row;

  .odometerInput {
    width: 205px;
  }

  .odometerUnitsSelect {
    font-size: 1.1em;
    margin-top: 1px;
    line-height: 1px;
    height: 30px !important;
    width: 75px;
  }
}
