@import '../../../../styles/variables';

.container {
  margin-top: $spacing-amount;
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.label {
  margin: 0 0.5rem !important;
  padding-top: 0.33em;
}
