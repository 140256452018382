@import '../../../../styles/variables';
@import '../../ScoringRequestDetails.module';

.inputDataGroup {
  padding-top: $spacing-amount * 2;
  padding-left: $spacing-amount/2;
  padding-right: $spacing-amount/2;
  width: 48%;

  &:nth-child(odd) {
    margin-right: 10px;
  }

  &:nth-child(even) {
    margin-left: 10px;
  }

  h5 {
    color: $primary-color;
    padding-top: $spacing-amount/2;
  }
}

.label {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-weight: $bold;
  justify-content: center;
  width: 30%;

  .copiedText {
    color: $primary-color;
    display: block;
    font-size: $font-size-small;
    margin-top: -$spacing-amount;
    opacity: 0;
    width: 100%;
    transition: opacity 0.5s ease-in-out;
  }
}

.printable {
  @include printableCollapseWrapper();
}
