@import '../../styles/variables';

.loanApplicationSectionHeader {
  display: flex;
  margin-bottom: $spacing-amount * 2;
  padding-top: $spacing-amount / 4;

  > span {
    color: $green-status-color;
    display: block;
    flex-grow: 2;
    font-weight: $bold;
  }

  .btnPrimary {
    color: $primary-color;
  }
}
